import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import 'index.css';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://cdc8e375b5262f380a273dd812c76fe6@o4508086168125440.ingest.de.sentry.io/4508398740242512',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/staging-client\.energie\.hellio\.dev/,
    /^https:\/\/dev-client\.energie\.hellio\.dev/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
  <Router history={history}>
    <App />
  </Router>,
);
